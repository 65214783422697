// https://www.schemecolor.com/green-plants.php

$font-size-base: 1rem !default;
$navbar-brand-font-size: $font-size-base * 2;
$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.3;

$primary: #40611a;

.wrapper {
  max-width: 1024px;
}

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

p {
  text-align: justify;
}